import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import colors from '../config/color';

class Homepage extends Component {
  render() {
    return (
      <Layout showHeader={true} showHero={false}>
        {/* TODO: SEO */}
        <SEO title="WoWindow" isNoAd={true} />
        {/* <div className="search-bar-up">
          <BigSearchBar categories={data.allWordpressCategory.edges} />
          <Product title={'好物篇章'} edges={data.allWordpressPost.edges} />
          <div className="more-btn-content">
            <Link style={{ textAlign: 'center' }} to={'/posts/page/1'}>
              <div className="more">看更多</div>
            </Link>
          </div>
          <Category edges={data.allWordpressCategory.edges} />
        </div> */}

        <div className="content">
          <h1>免責聲明</h1>
          <p>
            <br />
            感謝您來訪「好物櫥窗」(以下簡稱本網站)，任何瀏覽網站的人士，須自行承擔一切風險，本網站不會負責任何因瀏覽或使用本網站而引致之損失。本網站不會作出任何默示的擔保。
            <br />
            <br />
            本網站承諾力求網站內容之準確性及完整性，但內容如有錯誤或遺漏，本網站不會承擔任何賠償責任，所有本網站內容，將會隨時更改，而不作另行通知。
            <br />
            <br />
            您的隱私
            <br />
            <ul>
              <li>
                個人訊息：為了更好地保護您的隱私，我們提供此聲明，所有來信諮詢的信件我們不會將資料轉發給其他方或商家，信箱及個人資訊僅供本站與讀者聯繫。
              </li>
              <li>
                Cookies：為向用戶提供最佳服務，並維護服務與使用安全，我們可能會使用
                Cookies
                儲存用戶設定以記錄存取功能，並監控使用模式及數據。例如，我們可能會使用
                Google Analytics 協助我們進行上述工作。您可以選擇停用
                Cookies，但同時您將無法使用本服務的部分功能。
              </li>
              <li>
                記錄：存取本服務時，可能會自動取得並儲存您的使用紀錄、IP
                位址、瀏覽器類型、瀏覽器語言等存取記錄資訊。該資訊將用以分析您的用戶環境，以便我們提升本服務，並避免未經授權／盜用行為干擾本服務正常運作。
              </li>
            </ul>
            <br />
            聯盟行銷
            <br />
            <ul>
              <li>
                本網站從不同的服務 (包括 Google Adsense)
                和產品上面獲取返利佣金作為回報並維持本站營運的開銷，但這不影響您所購買任何商品的價格，也不影響本網站上所提供的任何正確信息。
              </li>
              <li>
                本網站將不負責任何讀者與商家之間的交易。任何取消、更改訂單請直接與商家客服聯絡。本網站將不參與及協助任何消費者與商家之間的糾紛。
              </li>
            </ul>
            <br />
            本網站可隨時停止或變更網頁資料及有關條款而毋須事前通知用戶。
            <br />
            <br />
            本網站不會對使用或連結本網頁而引致任何損害
            (包括但不限於電腦病毒、系統固障、資料損失)、誹謗、侵犯版權或知識產權所造成的損失，包括但不限於利潤、商譽、使用、資料損失或其他無形損失，本網站不承擔任何直接、間接、附帶、特別、衍生性或懲罰性賠償。
            <br />
            <br />
            本網站可能會連接至其他機構所提供的網頁，本公司不會對這些網頁內容作出任何保證或承擔任何責任。使用者如瀏覽這些網頁，將要自己承擔後果。是否使用本網站之服務下載或取得任何資料應由用戶自行考慮且自負風險，因前開任何資料之下載而導致用戶電腦系統之任何損壞或資料流失，本網站不承擔任何責任。
          </p>
        </div>

        <style jsx>{`
          h1 {
            font-size: 24px;
            color: ${colors['dirt-brown']};
            margin: 80px 0px 20px 0px;
            display: flex;
            justify-content: center;
          }

          p {
            font-size: 18px;
            margin-bottom: 1.5em;
            display: block;
            -webkit-margin-before: 1em;
            margin-block-start: 1em;
            -webkit-margin-after: 1em;
            margin-block-end: 1em;
            line-height: 1.9;
          }

          ul {
            padding-left: 40px;
          }

          li {
            list-style-type: disc;
            list-style-position: outside;
          }

          .content {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 75%;
            margin: auto;
          }
        `}</style>
      </Layout>
    );
  }
}

export default Homepage;

export const pageQuery = graphql`
  query {
    allWordpressCategory(filter: { slug: { nin: "noad" } }) {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    hotCategory: allWordpressCategory(
      sort: { fields: count, order: DESC }
      limit: 8
    ) {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    allWordpressPost(limit: 20) {
      edges {
        node {
          title
          slug
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
            yoast_wpseo_canonical
          }
          categories {
            wordpress_id
            id
            name
            slug
          }
          tags {
            id
            name
            slug
          }
          featured_media {
            source_url
            title
          }
        }
      }
    }
  }
`;
